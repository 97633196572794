import { Clipboard } from '@capacitor/clipboard'
import { debounce, DebouncedFunc } from 'lodash'

interface ICapacitorClipboard {
  copyToClipboard: DebouncedFunc<(text?: string) => Promise<void>>
}

export default class CapacitorClipboard implements ICapacitorClipboard {
  copyToClipboard: DebouncedFunc<(text?: string) => Promise<void>>

  private _copyToClipboard (text?: string) {
    return Clipboard.write({ string: text })
  }

  constructor () {
    this.copyToClipboard = debounce(this._copyToClipboard, 900)
  }
}
